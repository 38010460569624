import React, { Component } from 'react';
import { navigateTo } from 'gatsby-link';
import styled from 'styled-components';
import { Container, Row, Column, Button } from 'styled-bootstrap-components';

import theme from '../../styled/theme';
import { Grid } from '../../styled';

const Input = styled.input`
  background: ${theme.colors.grayLight};
  border: 0;
  box-sizing: border-box;
  font-size: 1rem;
  margin-bottom: 1rem;
  outline: 0;
  padding: 1rem;
  width: 100%;
`;

const TextArea = Input.withComponent('textarea');

const encode = data => {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join('&');
}

class ContactForm extends Component {
  state = {
    firstName: '',
    lastName: '',
    email: '',
    message: '',
  }

  handleSubmit = e => {
    e.preventDefault();
    const form = e.target;

    fetch('/', {
      method: 'POST',
      headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
      body: encode({
        'form-name': form.getAttribute('name'),
        ...this.state,
      })
    })
      .then(() => navigateTo(form.getAttribute('action')))
      .catch(error => console.error(error));
  };

  handleChange = e => this.setState({ [e.target.name]: e.target.value })

  render() {
    const { firstName, lastName, email, message } = this.state;

    return (
      <form name="contacto" method="post" action="/gracias/" data-netlify="true" data-netlify-honeypot="bot-field" onSubmit={this.handleSubmit}>
        <input type="hidden" name="form-name" value="contacto" />
        <Grid columns="2">
          <div>
            <label></label>
            <Input type="text" name="firstName" value={firstName} onChange={this.handleChange} placeholder="Primer nombre" />
          </div>
          <div>
            <label></label>
            <Input type="text" name="lastName" value={lastName} onChange={this.handleChange} placeholder="Apellido" />
          </div>
        </Grid>
        <div>
          <label></label>
          <Input type="text" name="email" value={email} onChange={this.handleChange} placeholder="Correo electrónico" />
        </div>
        <div>
          <label></label>
          <TextArea type="text" name="message" value={message} onChange={this.handleChange} rows="8" placeholder="Mensaje" />
        </div>
        <Button bg="primary" color="white" lg>Enviar</Button>
      </form>
    )
  }
}

export default ContactForm;