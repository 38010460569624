import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import whatsapp from '@fortawesome/fontawesome-free-brands/faWhatsapp';
import { Container, Row, Column } from 'styled-bootstrap-components';

import Layout from '../components/Layout';
import ContactForm from '../components/ContactForm';
import { Text, FlexRow } from '../styled';
import theme from '../styled/theme';
import logo from '../images/logo.png';
import { formatPhoneNumber } from '../util/helpers';

const PageGrid = styled.div`
  position: absolute;
  top: 0;
  z-index: -1;
`;

const StyledContainer = styled(Container)`
  @media (max-width: 767px) {
    ${Row} {
      margin-top: 1rem;
      padding: 2rem;
    }
  }
`;

const ContactIcon = styled(FontAwesomeIcon)`
  color: ${theme.colors.primary};
  font-size: 1.5rem;
  margin-right: 0.5rem;
`;

const ContactLink = styled.a`
  color: ${theme.colors.gray};
  font-size: 1.25rem;
  text-decoration: none;
  line-height: normal;
`;

const Contact = props => {
  const { data } = props;
  const image = data.wordpressPage.featured_media;

  return (
    <Layout location={props.location}>
      <Helmet title={`${data.wordpressPage.title} - Eddy Herrera`} />
      <PageGrid>{image && <Img resolutions={image.localFile.childImageSharp.resolutions} />}</PageGrid>
      <StyledContainer mb="1.5rem">
        <Row justifyContent={image ? 'flex-end' : 'flex-start'} mt="5rem">
          <Column md={image ? 8 : 12} bg="white" p="2rem">
            <div dangerouslySetInnerHTML={{ __html: data.wordpressPage.content }} />
            <h2 style={{ marginTop: 0 }}>Booking</h2>
            <FlexRow pb="1rem" borderBottom="1px solid" borderColor="grayLight" flexWrap="wrap">
              <FlexRow alignItems="start" mr="2rem" mb="0.25rem">
                <ContactIcon icon={faPhone} />
                <ContactLink href={`tel:${data.wordpressPage.acf.phone_number}`}>
                  {formatPhoneNumber(data.wordpressPage.acf.phone_number)}
                </ContactLink>
              </FlexRow>
              <FlexRow alignItems="start" mr="2rem" mb="0.25rem">
                <ContactIcon icon={whatsapp} />
                <ContactLink href={`tel:${data.wordpressPage.acf.whatsapp_number}`}>
                  {formatPhoneNumber(data.wordpressPage.acf.whatsapp_number)}
                  <div>
                    <small>Martha Herrera</small>
                  </div>
                </ContactLink>
              </FlexRow>
              <FlexRow alignItems="start" mb="0.25rem">
                <ContactIcon icon={faEnvelope} />
                <ContactLink href={`mailto:${data.wordpressPage.acf.email_address}`}>
                  {data.wordpressPage.acf.email_address}
                </ContactLink>
              </FlexRow>
            </FlexRow>
            <h2>Consultas</h2>
            <Text color="gray" fontSize="1.125rem" mb="2rem">
              Para consultas generales por favor utilice el siguiente formulario.
            </Text>
            <ContactForm />
          </Column>
        </Row>
      </StyledContainer>
      <Container>
        <Row justifyContent="flex-end">
          <Column sm={6} md={8}>
            <img src={logo} alt="Eddy Herrera" />
          </Column>
        </Row>
      </Container>
    </Layout>
  );
};

export const query = graphql`
  query ContactPageQuery {
    wordpressPage(slug: { eq: "contacto" }) {
      title
      slug
      content
      acf {
        phone_number
        whatsapp_number
        email_address
      }
      featured_media {
        alt_text
        localFile {
          childImageSharp {
            resolutions(width: 720, height: 1080) {
              ...GatsbyImageSharpResolutions
            }
          }
        }
      }
    }
  }
`;

export default Contact;
